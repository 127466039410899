import { defineStore } from 'pinia';

import { useApi } from '@/shared/composables';

import { type SpecInfo } from '../lib/SpecInfo.types';

// TODO: Вынести в entity SpecInfo
export const useSpecInfoStore = defineStore('SpecInfo', {
  state: (): SpecInfo => ({
    brand: '',
    model: '',
    modelYear: 0,
    admeId: 0,
    seats: '',
    vehicleValue: 0,
    vehicleLowValue: 0,
    vehicleHighValue: 0,
    cylinders: '',
    gccSpec: '0',
  }),
  actions: {
    setVehicleValue(payload: number) {
      this.vehicleValue = payload;
    },

    setGccSpec(payload: string) {
      this.gccSpec = payload;
    },

    setSpecInfo(payload: SpecInfo) {
      this.$patch(payload);
    },

    async fetchSpecInfo(
      brandCode: string,
      modelCode: string,
      modelYear: number,
      admeId: number,
      chassisNumber: string,
    ) {
      const specInfo = await useApi().flowService.getSpecInfo({
        brand: brandCode,
        model: modelCode,
        modelYear: modelYear,
        admeId: admeId,
        chassisNumber: chassisNumber,
      });

      this.setSpecInfo(specInfo);
    },

    // костыль, чтобы не перезаписывать значение, которое вводит пользователь в форме
    async fetchSpecInfoForCarDetails(
      brandCode: string,
      modelCode: string,
      modelYear: number,
      admeId: number,
      chassisNumber: string,
    ) {
      const specInfo = await useApi().flowService.getSpecInfo({
        brand: brandCode,
        model: modelCode,
        modelYear: modelYear,
        admeId: admeId,
        chassisNumber: chassisNumber,
      });

      // @ts-ignore
      delete specInfo.gccSpec;

      this.setSpecInfo(specInfo);
    },
  },
});
