import { formatDate } from '@qic/ui-components';
import { defineAsyncComponent, toValue } from 'vue';
import type { ErrorObject } from '@vuelidate/core';

const numberFloatFormat = (value: number) =>
  new Intl.NumberFormat('en-US', { minimumFractionDigits: value });

export const stringToDate = (value: string) => {
  if (!value) {
    return null;
  }

  const year = value.substring(0, 4);
  const month = value.substring(4, 6);
  const day = value.substring(6);
  const date = `${year}-${month}-${day}`;

  return new Date(date);
};

export const getYear = (value: string) => {
  if (!value) {
    return null;
  }

  const year = value.substring(0, 4);

  return Number(year);
};

export const defaultFormatDate = (
  date: Date,
  locale: LocaleType = 'en',
  mask: string = 'dd MMMM yyyy',
) => formatDate(date, locale, mask);

export const formateDateForSend = (date: Nullable<Date>) => {
  if (date === null) {
    return null;
  }

  const tzOffset = date.getTimezoneOffset() * 60000;
  const time = Math.round((date.getTime() - tzOffset) / 1000);
  return String(time);
};

export const getAsyncQicUiComponent = (componentName: string) =>
  defineAsyncComponent(() =>
    import('@qic/ui-components').then((components: any) => components[componentName]),
  );

export const formatNumber = (value: number, fixed = 3): string =>
  numberFloatFormat(fixed).format(value);
formatNumber.separator = ',';

export const getAgeByDateByDivider = (dateString: string): number => {
  const parts = dateString.split('/');
  const day = parseInt(parts[0]);
  const month = parseInt(parts[1]) - 1;
  const year = parseInt(parts[2]);
  const birthDate = new Date(year, month, day);
  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const wasBirthdayCurrentYear: boolean =
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate());
  if (wasBirthdayCurrentYear) {
    age -= 1;
  }
  return age;
};

export const dateStringToDate = (dateString: string): Date => {
  const parts = dateString.split('/');
  const day = parseInt(parts[0]);
  const month = parseInt(parts[1]) - 1;
  const year = parseInt(parts[2]);
  return new Date(year, month, day);
};

export const getLandingPageUrl = (locale: string): string => {
  const domain = window.location.origin;
  return `${domain}/${locale}/car-insurance`;
};

export const getValidationErrorMessages = (error: ErrorObject): string => toValue(error.$message);
export const errorsMessages = (errors: ErrorObject[] = []) =>
  errors.map((error) => toValue(error.$message));

export const getFieldsNames = (errors: ErrorObject[] = []) =>
  errors.map((error) => toValue(error.$property));

export const errorHandler = (err: any) => {
  console.error(err);
  throw err;
};
