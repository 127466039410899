import type { DocumentInfo } from '@/entities/quote/quote.types';

export enum TYPES_FEE {
  SUPPORT_CODE = '22',
  LOCAL_INSURANCE = '21',
  EMERGENCY_FEE = '24',
}

export enum TYPE_OF_CALCULATE {
  FINAL_PRICE = 1,
  SHOW_PRICE = 0,
}

enum DocumentCode {
  VehicleFront = '033',
  VehicleBack = '034',
  VehicleRight = '036',
  VehicleLeft = '035',
  MulkiaFront = '008',
  MulkiaBack = '043',
  LicenseFront = '007',
  LicenseBack = '00701',
  InvoiceFront = '021',
}
export enum DocumentTitle {
  VehiclePhotos = 'tpl_flow.docs.vehicle_photos',
  Mulkia = 'tpl_flow.docs.mulkia',
  DriverLicense = 'tpl_flow.docs.driver_license',
  Invoice = 'tpl_flow.docs.invoice',
}

export enum PopupTitle {
  Car = 'tpl_flow.popup.docs.title.car',
  Mulkia = 'tpl_flow.popup.docs.title.mulkia',
  License = 'tpl_flow.popup.docs.title.license',
  VehicleDetails = 'tpl_flow.popup.docs.title.vehicle_details',
}

export const DOCUMENT_LIST: DocumentInfo[] = [
  {
    title: DocumentTitle.VehiclePhotos,
    isSuccess: false,
    titlePopup: PopupTitle.Car,
    error: '',
    warning: '',
    documents: [
      {
        id: 'front',
        label: 'tpl_flow.popup.docs.button.front',
        img: '',
        docCode: DocumentCode.VehicleFront,
        loaded: false,
      },
      {
        id: 'back',
        label: 'tpl_flow.popup.docs.button.back',
        img: '',
        docCode: DocumentCode.VehicleBack,
        loaded: false,
      },
      {
        id: 'right',
        label: 'tpl_flow.popup.docs.button.right',
        img: '',
        docCode: DocumentCode.VehicleRight,
        loaded: false,
      },
      {
        id: 'left',
        label: 'tpl_flow.popup.docs.button.left',
        img: '',
        docCode: DocumentCode.VehicleLeft,
        loaded: false,
      },
    ],
  },
  {
    title: DocumentTitle.Mulkia,
    isSuccess: false,
    error: '',
    titlePopup: PopupTitle.Mulkia,
    warning: '',
    documents: [
      {
        id: 'front',
        label: 'tpl_flow.popup.docs.button.front',
        img: '',
        docCode: DocumentCode.MulkiaFront,
        loaded: false,
      },
      {
        id: 'back',
        label: 'tpl_flow.popup.docs.button.back',
        img: '',
        docCode: DocumentCode.MulkiaBack,
        loaded: false,
      },
    ],
  },
  {
    title: DocumentTitle.DriverLicense,
    isSuccess: false,
    titlePopup: PopupTitle.License,
    error: '',
    warning: '',
    documents: [
      {
        id: 'front',
        label: 'tpl_flow.popup.docs.button.front',
        img: '',
        docCode: DocumentCode.LicenseFront,
        loaded: false,
      },
      {
        id: 'back',
        label: 'tpl_flow.popup.docs.button.back',
        img: '',
        docCode: DocumentCode.LicenseBack,
        loaded: false,
      },
    ],
  },
  {
    title: DocumentTitle.Invoice,
    titlePopup: PopupTitle.VehicleDetails,
    isSuccess: false,
    error: '',
    warning: '',
    documents: [
      {
        id: 'front',
        label: 'tpl_flow.popup.docs.button.front',
        img: '',
        docCode: DocumentCode.InvoiceFront,
        loaded: false,
      },
    ],
  },
];
