import HttpService from '../HttpService';
import { type ApiFields } from '../api.types';
import { serializeToFormData } from '../api.utils';
import { dateStringToDate, getAgeByDateByDivider, stringToDate, getYear } from '@/shared/utils';
import type {
  ActivePolicyInfo,
  ApiCarBrand,
  ApiCarModel,
  DriverApi,
  DriverResponse,
  GetSpecInfoParams,
  GetSpecInfoParamsSerialized,
  GetSpecInfoResponse,
  GetVehicleSpecsParams,
  GetVehicleSpecsParamsSerialized,
  LicenseInfo,
  RequestGetTotalPrice,
  RequestSendDocument,
  ResponseActivePolicy,
  ResponseDocumentList,
  ResponseGetBrands,
  ResponseGetLicenseInfo,
  ResponseGetPlateTypes,
  ResponseGetTotalPrice,
  ResponseGetVehicleDescriptionByChassisNumber,
  ResponseGetVehicleDescriptionByPlate,
  ResponseGetVehicleSpecs,
  ResponseVehicleTypes,
  VehicleSpecs,
  VehicleType,
  Wording,
} from './FlowService.types';
import type { Car, GetVehicleByPlateParams, SpecInfo } from '@/entities/Car';
import { bodyCodeMapping } from '@/entities/Car';
import type {
  Policy,
  RenewQuote,
  QuoteType,
  PolicySmsStatus,
} from '@/shared/types/flowStore.types';

export class FlowService extends HttpService {
  private PHONE_NUMBER_LENGTH_WITHOUT_COUNTRY_CODE = 8;

  async sendFormData(payload: Partial<ApiFields>): Promise<void> {
    const formData = serializeToFormData(payload);
    await this.post('/sendformdata', formData);
  }

  async getPlateTypes(): Promise<NameCode[]> {
    const response: ResponseGetPlateTypes[] = await this.get('/oqic/getplatetypes');
    return response.map((types) => ({ name: types.name, code: types.id }));
  }

  // TODO: refactor: move to entities/Car/api/Car.service
  private serializeToCarInfo(
    responseWithCarInfo:
      | ResponseGetVehicleDescriptionByPlate
      | ResponseGetVehicleDescriptionByChassisNumber,
  ): Car {
    const carInfo: Car = {
      chassisNumber: responseWithCarInfo.chassisNumber,
      model: { name: responseWithCarInfo.modelname, code: responseWithCarInfo.model },
      brand: { name: responseWithCarInfo.brandname, code: responseWithCarInfo.brand },
      modelYear: Number(responseWithCarInfo.year),
      seats: responseWithCarInfo.seats[0],
      cylinders: responseWithCarInfo.cylinders[0],
      vehicleUsage: {
        name: responseWithCarInfo.vehicleTypeName,
        code: bodyCodeMapping[Number(responseWithCarInfo.vehicleType)],
      },
      body: {
        name: responseWithCarInfo.bodytype,
        code: bodyCodeMapping[Number(responseWithCarInfo.bodytypecode)],
      },
      plateCharacter: responseWithCarInfo.plateCat,
      plateNumber: responseWithCarInfo.plate,
      insuranceEndDate: stringToDate(responseWithCarInfo.insuranceEndDate) as Date,
      firstRegistrationYear: getYear(responseWithCarInfo.registrationIssueDate),
      admeId: Number(responseWithCarInfo.admeId),
      engineNumber: responseWithCarInfo.engineNumber,
      // TODO: локальные поля на фронте. Нужно будет их вытащить отсюда
      isPlateNotFound: null,
      isNoPlateNumber: false,
      vehicleError: null,
    };
    return carInfo;
  }

  private serializeToDriversList(responseWithDriversList: DriverResponse[]) {
    const driversList = responseWithDriversList
      .filter((driver) => driver.srNo !== 1)
      .sort((a, b) => a.srNo - b.srNo)
      .map((driver) => ({
        firstName: driver.firstName,
        familyName: driver.lastName,
        age: getAgeByDateByDivider(driver.dateOfBirth),
        drivingExperienceOman: driver.drivingExp,
        srNo: driver.srNo,
      }));

    return driversList;
  }

  private serializeToActivePolicy(
    responseWithActivePolicy: ResponseActivePolicy,
    brandName?: string,
    modelName?: string,
  ): ActivePolicyInfo {
    const fullName = responseWithActivePolicy.insName.split(' ');
    const firstName = fullName[0];
    const lastName = fullName.slice(1).join(' ');
    responseWithActivePolicy.insName;

    const policyInfo = {
      holder: {
        licenseNumber: responseWithActivePolicy.civilId,
        firstName: firstName,
        familyName: lastName,
        phone:
          responseWithActivePolicy.mobileNo.length > this.PHONE_NUMBER_LENGTH_WITHOUT_COUNTRY_CODE
            ? responseWithActivePolicy.mobileNo
            : `968${responseWithActivePolicy.mobileNo}`,
        dateOfBirth: responseWithActivePolicy.driverDOB
          ? dateStringToDate(responseWithActivePolicy.driverDOB)
          : null,
        email: responseWithActivePolicy.emailId,
        drivingExperienceOman: null,
      },
      car: {
        chassisNumber: responseWithActivePolicy.chassisNo,
        model: {
          name: modelName ?? responseWithActivePolicy.model,
          code: responseWithActivePolicy.modelCode,
        },
        brand: {
          name: brandName ?? responseWithActivePolicy.make,
          code: responseWithActivePolicy.makeCode,
        },
        modelYear: Number(responseWithActivePolicy.modelYear),
        seats: responseWithActivePolicy.seatingCapacity,
        cylinders: responseWithActivePolicy.noOfCylinder,
        firstRegistrationYear: Number(responseWithActivePolicy.regYear),
        body: {
          name: responseWithActivePolicy.vehicleType,
          code: responseWithActivePolicy.vehicleTypeCode,
        },
        plateCharacter: responseWithActivePolicy.plateCharacter,
        plateNumber: responseWithActivePolicy.regNo,
      },
      startPolicyDate: responseWithActivePolicy.polStartDate,
      endPolicyDate: responseWithActivePolicy.polEndDate,
      policyNumber: responseWithActivePolicy.policyNo,
      quoteNameCode: responseWithActivePolicy.prodCode,
      geoAreaCode: responseWithActivePolicy.crusingArea,
      policyStatus: responseWithActivePolicy.policyStatus,
      prodCode: responseWithActivePolicy.prodCode,
    };

    return policyInfo;
  }

  async getVehicleDescriptionByPlate(vehicle: GetVehicleByPlateParams): Promise<Car> {
    const formData = serializeToFormData(vehicle, false);

    const response: ResponseGetVehicleDescriptionByPlate = await this.post(
      '/oqic/getvehicledescriptionbyplate',
      formData,
    );
    return this.serializeToCarInfo(response);
  }

  async getBrands(): Promise<NameCode[]> {
    const response: ResponseGetBrands = await this.get('/oqic/getadbrands');
    const popularBrands = response
      .filter((brand) => brand.popular)
      .sort((a, b) => a.name.localeCompare(b.name));
    const otherBrands = response
      .filter((brand) => !brand.popular)
      .sort((a, b) => a.name.localeCompare(b.name));
    return popularBrands
      .concat(otherBrands)
      .map((brand: ApiCarBrand): NameCode => ({ name: brand.name, code: brand.id }));
  }

  async getModels(brand: string): Promise<NameCode[] | []> {
    const response: ApiCarModel[] = await this.get('/oqic/getadmodels', { params: { brand } });
    return response
      .sort((a, b) => {
        // Сначала сортируем по popular: true в алфавитном порядке
        if (a.popular === b.popular) {
          return a.name.localeCompare(b.name);
        }
        return a.popular ? -1 : 1;
      })
      .map((brand: ApiCarModel): NameCode => ({ name: brand.name, code: brand.id }));
  }

  async getBrandsAnoud(): Promise<ApiCarModel[]> {
    return await this.get('/oqic/getbrands');
  }

  async getModelsAnoud(brand: string): Promise<ApiCarModel[]> {
    return await this.get('/oqic/getmodels', { params: { brand } });
  }

  async getVehicleTypes(): Promise<NameCode[]> {
    const response: ResponseVehicleTypes = await this.get('/oqic/getvehicletypes');
    return Object.values(response).map((vehicleType: VehicleType) => ({
      name: vehicleType.name,
      code: vehicleType.id,
    }));
  }

  async getSpecInfo(getSpecInfoParams: GetSpecInfoParams): Promise<SpecInfo> {
    const { brand: make, model, modelYear: year, admeId, chassisNumber } = getSpecInfoParams;
    const params: GetSpecInfoParamsSerialized = {
      make,
      model,
      year,
      admeId: String(admeId),
      chassisNumber,
    };
    const response: GetSpecInfoResponse = await this.get('/oqic/getspecinfo', { params });

    return {
      brand: response.vehMake,
      model: response.vehModel,
      modelYear: Number(response.modelYear),
      admeId: response.admeId,
      seats: response.seats,
      vehicleValue: response.vehicleValue,
      vehicleLowValue: response.vehicleLowValue,
      vehicleHighValue: response.vehicleHighValue,
      cylinders: response.cylinders,
      gccSpec: response.gccSpecYn,
    };
  }

  async getVehicleSpecs(carParams: GetVehicleSpecsParams): Promise<VehicleSpecs> {
    const { brand: make, model, modelYear: year, chassisNumber } = carParams;
    const params: GetVehicleSpecsParamsSerialized = { make, model, year };
    if (chassisNumber) {
      params.chassisNo = chassisNumber;
    }
    const response: ResponseGetVehicleSpecs = await this.get('/oqic/getvehiclespecs', { params });
    return {
      brand: response.vehMake,
      model: response.vehModel,
      modelYear: Number(response.year),
      body: response.bodytype,
      cylinders: response.cylinders,
      seats: response.seats,
      carDescriptions: response.data,
    };
  }

  async getLicenseInfo(licenseNumber: string): Promise<LicenseInfo> {
    const response: ResponseGetLicenseInfo = await this.get('/oqic/getlicenseinfo', {
      params: { LicenseNumber: licenseNumber },
    });

    return {
      licenseNumber: response.LicenseNumber,
      drivingExp: response.DrivingExp || 0,
      dateOfBirth: response.DateofBirth === '' ? null : dateStringToDate(response.DateofBirth),
    };
  }

  async fetchQuotes(params: { geoArea: string; locale: string }): Promise<QuoteType[]> {
    return await this.get('/oqic/getomanquote', { params });
  }

  async updateQuote(params: { quoteNo: string }) {
    return await this.get('/oqic/updatequotedetails', { params });
  }

  async getListOfDrivers(): Promise<DriverApi[]> {
    const list: DriverResponse[] = await this.get('/oqic/getdrivers');
    return this.serializeToDriversList(list);
  }

  async getVehicleDescriptionByChassisNumber(params: {
    chassisNumber: string;
    response_token: string;
  }): Promise<Car> {
    const formData = serializeToFormData(params, false);

    const response: ResponseGetVehicleDescriptionByChassisNumber = await this.post(
      '/oqic/getvehicledescriptionbychassis',
      formData,
    );
    return this.serializeToCarInfo(response);
  }

  async saveDriver(params: any) {
    const data = serializeToFormData(params, false);
    return await this.post('/oqic/savedriver', data);
  }

  async removeDriver(srNo: number) {
    const data = serializeToFormData({ srNo: srNo }, false);
    return await this.post('oqic/removedriver', data);
  }

  async fetchTotalPrice(
    requestGetTotalPrice: RequestGetTotalPrice,
  ): Promise<ResponseGetTotalPrice> {
    return await this.get('oqic/gettotalprice', { params: requestGetTotalPrice });
  }

  async sendDocument(requestSendDocument: RequestSendDocument, callback: (event: any) => void) {
    const formData = new FormData();
    formData.append('file', requestSendDocument.file);
    formData.append('doccode', requestSendDocument.doccode);
    formData.append('quoteno', requestSendDocument.quoteNumber);
    return await this.post('oqic/uploadquotedocs', formData, { onUploadProgress: callback });
  }

  async getDocumentList(quoteNo: string): Promise<ResponseDocumentList[]> {
    return await this.get('oqic/getDocumentsListByQuote', { params: { quoteNo } });
  }

  async fetchPolice(): Promise<Policy> {
    return await this.get('/getpolicy');
  }

  async fetchPolicylinkbysms(): Promise<PolicySmsStatus> {
    return await this.get('/sendpolicylinkbysms');
  }

  async getActivePolicyInfo(params: {
    policyNo: string;
    transId?: string;
    civilId?: string;
  }): Promise<ActivePolicyInfo> {
    const [activePolicy]: ResponseActivePolicy[] = await this.get('/oqic/listactivepolicies', {
      params: { ...params, Region: 'om' },
    });

    const [make, model] = activePolicy.makeModel.split('-').map((item: string) => item.trim());

    return this.serializeToActivePolicy(activePolicy, make, model);
  }

  async fetchRenewalQuote(policyNo: string, transId: string): Promise<RenewQuote> {
    return await this.get<RenewQuote>('/oqic/policyrenewal', {
      params: {
        policyNo,
        transId,
      },
    });
  }

  async getWordings(id: string, name: string): Promise<string> {
    const [wordings]: Wording[] = await this.get('/oqic/getwordings', {
      params: {
        categoryid: id,
        docname: name,
      },
    });

    return wordings.Link;
  }

  async getPolicyProductType(policyNo: string): Promise<string> {
    const prodCode: string = await this.get('/oqic/getpolicyproducttype', {
      params: { policyNo: policyNo },
    });

    return prodCode;
  }
}
