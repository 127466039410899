import { defineStore } from 'pinia';
import { useApi } from '@/shared/composables/useApi';
import type { Driver, SaveDriver } from '../lib';
import type { ApiFields } from '@/shared/api/api.types';

export const useDriverStore = defineStore('DriverStore', {
  state: (): Driver => ({
    drivers: [],
    editDriverId: null,
  }),
  actions: {
    setDrivers(drivers: Driver['drivers']) {
      this.drivers = drivers;
    },

    async save(params: Partial<ApiFields>) {
      await useApi().flowService.sendFormData(params);
    },

    async saveDriver(params: SaveDriver) {
      return useApi().flowService.saveDriver(params);
    },

    async getDrivers() {
      this.drivers = await useApi().flowService.getListOfDrivers();
    },

    async removeDriver(srNo: number) {
      return useApi().flowService.removeDriver(srNo);
    },

    setEditDriverId(srNo: Nullable<number>) {
      this.editDriverId = srNo;
    },
  },
});
