// TODO: Backend API must provide fields in one style (kebab or camel not both as is)

export const API_FIELDS_KEYS_MAP = {
  policyDate: 'policy_date',
  firstName: 'first_name',
  familyName: 'last_name',
  dateOfBirth: 'birth_date',
  phone: 'phone',
  plateNumber: 'plate',
  plateCharacter: 'plateCat',
  plateType: 'plateType',
  geoAreaCode: 'geoArea',
  quoteNumber: 'quoteno',
  chassisNumber: 'chassisNumber',
  chassis: 'chassis',
  srNo: 'srNo',
  email: 'email',
  drivingExperienceOman: 'DrivingExperience',
  licenseNumber: 'EmiratesID',
  // TODO: might be breaking change
  modelYear: 'model_year',
  brandName: 'brandname',
  modelName: 'modelname',
  firstRegistrationYear: 'year_registration',
  bodyCode: 'vehicle_type',
  bodyName: 'vehicletypename',
  admeId: 'adTrimID',
  startPolicyDate: 'policy_date',
  responseToken: 'response_token',
  brand: 'brand',
  model: 'model',
  seats: 'seats',
  vehicleUsage: 'vehicleUsage',
  cylinders: 'cylinders',
  vehicle_value: 'vehicle_value',
  gccSpec: 'GccSpec',
  engineNumber: 'engineno',
};

export const SUCCESS: string = 'success';
