import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { autoBindDecorator } from './api.utils';

import FlowService from './FlowService';

export interface AxiosError {
  errorMessage: string;
  errorCode: string;
  data: unknown;
}

class ApiErrors {
  static NO_NAME_ERROR = 'No name error';
  static NETWORK_ERROR = 'Network error';
}

export const apiBuilder = (config: AxiosRequestConfig) => {
  const http: AxiosInstance = axios.create(config);

  const onFulfilled = function (response: AxiosResponse) {
    if (response.data && response.data.success !== undefined && !response.data.success) {
      if (response.data.quoteno) {
        return Promise.reject(response.data || ApiErrors.NO_NAME_ERROR);
      } else {
        return Promise.reject(response.data.errorCode || ApiErrors.NO_NAME_ERROR);
      }
    }

    const { success, errorMessage, errorCode, ...payload } = response.data;

    if (Object.keys(payload).length === 1) {
      return Object.values(payload)[0];
    }

    return payload;
  };

  const onRejected = function (error: any): Promise<AxiosError> {
    const { success, errorMessage, errorCode, ...payload } = error.response.data;

    return Promise.reject({
      errorMessage: errorMessage,
      errorCode: errorCode ?? ApiErrors.NO_NAME_ERROR,
      data: payload,
    });
  };

  http.interceptors.response.use(onFulfilled, onRejected);

  return {
    flowService: autoBindDecorator(new FlowService(http)),
  };
};
